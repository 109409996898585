import React from 'react';
import { Link } from 'react-router-dom';
import rice from '../images/rice_1.png';
import avocado from '../images/avocado_1.png';
import pineapple from '../images/pineapple_1.png';
import mango from '../images/mango_1.png';
import banana from '../images/banana_1.png';
import dragon from '../images/dragon_1.png';
import lime from '../images/lime_1.png';
import cardamom from '../images/cardamom_1.png';
import canola from '../images/canola_1.png';
import coffee from '../images/coffee.png';

const productData = [
  {
    name: 'India Basmati Rice',
    image: rice,
    description: '',
    details: [
      { label: 'Rice Type', value: '1121 Basmati Steam Rice' },
      { label: 'Bags Container 20ft(FCL)', value: '1,275' },
      { label: 'Bag size', value: '20kg' },
      { label: 'Bag Thickness', value: '120 gsm' },
      { label: 'Bag Type', value: 'Non Woven Bags, Rectangular, Color Printed' },
      { label: 'HS Code', value: '1006 30' },
    ],
  },
  {
    name: 'Hass Avocado',
    image: avocado,
    description: '',
    details: [
      { label: 'Case size', value: '40cm x 30cm x 9,5cm' },
      { label: 'Net weight', value: '6 kg' },
      { label: 'Gross weight', value: '6.5 kg' },
      { label: 'Units per box', value: '20-24 pieces' },
      { label: 'Cases for pallet', value: '120 cases' },
      { label: 'Pallet/Container', value: '19' },
      { label: 'Units/Container', value: '45,600' },
    ],
  },
  {
    name: 'Pineapple MD2-Honey',
    image: pineapple,
    description: '',
    details: [
      { label: 'Color', value: 'Gold' },
      { label: 'Net weight', value: '12 kg' },
      { label: 'Gross weight', value: '13 kg' },
      { label: 'Units per box', value: '5-8 pieces' },
      { label: 'Cases for pallet', value: '72 cases' },
      { label: 'Pallet/Container', value: '20' },
      { label: 'Units/Container', value: '~7,200' },
    ],
  },
  {
    name: 'Baby Colombia Mango',
    image: mango,
    description: '',
    details: [
      { label: 'Case size', value: '34,5cm x 24,5cm x 9,5cm' },
      { label: 'Net weight', value: '2 kg' },
      { label: 'Gross weight', value: '2.5 kg' },
      { label: 'Units per box', value: '12-24 pieces' },
      { label: 'Cases for pallet', value: '165 cases' },
      { label: 'Pallet/Container', value: '20' },
      { label: 'Units/Container', value: '~58,500' },
    ],
  },
  {
    name: 'Yellow Dragon Fruit or Pitahaya',
    image: dragon,
    description: '',
    details: [
      { label: 'Case size', value: '34,5cm x 24,5cm x 9,5cm' },
      { label: 'Net weight', value: '2.5 kg net' },
      { label: 'Gross weight', value: '3 kg net' },
      { label: 'Units per box', value: '6-12 pieces' },
      { label: 'Cases for pallet', value: '165 cases' },
      { label: 'Pallet/Container', value: '20' },
      { label: 'Units/Container', value: '~39,600' },
    ],
  },
  {
    name: 'Baby Banana',
    image: banana,
    description: '',
    details: [
      { label: 'Case size', value: '40cm x 30cm x 9,5cm' },
      { label: 'Net weight', value: '10 kg net' },
      { label: 'Gross weight', value: '11.5 kg net' },
      { label: 'Units per box', value: '33-36 pieces' },
      { label: 'Cases for pallet', value: '72 cases' },
      { label: 'Pallet/Container', value: '20' },
      { label: 'Units/Container', value: '~49,680' },
    ],
  },
  {
    name: 'Tahiti Lemon (Lime)',
    image: lime,
    description: '',
    details: [
      { label: 'Net weight', value: '4 kg net' },
      { label: 'Gross weight', value: '4,5 kg net' },
      { label: 'Units per box', value: '40 pieces' },
      { label: 'Cases for pallet', value: '150 cases' },
      { label: 'Pallet/Container', value: '20' },
      { label: 'Units/Container', value: '~120,000' },
    ],
  },
  {
    name: 'Cardamom',
    image: cardamom,
    description: '',
    details: [
      { label: 'Polyethylene bags', value: '1kg-5kg available' },
      { label: 'Net weight', value: '15 kg net' },
      { label: 'Gross weight', value: '16.5 kg net' },
      { label: 'Units per box', value: '3-5 pieces' },
      { label: 'Cases for pallet', value: '54 cases' },
      { label: 'Pallet/Container', value: '20' },
      { label: 'Units/Container', value: '~5,400 (1kg bags)' },
    ],
  },
  {
    name: `Unrefined Rapeseed Oil (Canola Oil - Food Grade)`,
    image: canola,
    description: 'Canola oil, also known as low erucic acid rapeseed (LEAR) oil, is a food-grade oil extracted from rapeseed cultivars that have been specifically bred to contain low levels of erucic acid. It is considered safe for consumption by the United States Food and Drug Administration (FDA).',
    details: [
      { label: 'Case size', value: '1,000 Liters/Unit' },
      { label: 'Liters/Container', value: '20,000' },
      { label: 'Units/Container', value: '20' },
    ],
  },
  {
    name: `Arabica  Coffee Roasted & Green Beans`,
    image: coffee,
    description: `Sources: Latin America, SE. Asia, MENA \n 
    Green coffee beans are the unroasted seeds of coffee cherries, containing all the potential flavor and taste of the final product.\n
Roasting unlocks this potential, transforming the chemical and physical properties of the green beans into the familiar flavor and aroma of roasted coffee.\n
Through roasting, the green beans undergo a significant change in taste, resulting in the characteristic flavor of coffee.\n
Arabica coffee, considered the original cultivated species, accounts for approximately 60% of global coffee production.`,
    details: [
      { label: 'Case size', value: '22,000 MT / FCL' },
      { label: 'MOQ/Container', value: '5' },
    ],
  },
];

const Product = ({ name, image, description, details }) => {
  return (
    <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
      <div className="m-2 text-justify text-sm flex flex-col justify-between h-full">
        <div>
          <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">{name}</h4>
          <img src={image} alt={name} />
          <div className={` ${description.length === 0  ?' mt-0'  : ' mt-5'} `}>
            <p>
              {description.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
            </div>
          <div className="py-5">
            {details.map((detail, index) => (
              <p key={index}>
                <span className="font-black">{detail.label}:</span> {detail.value}
              </p>
            ))}
          </div>
        </div>
        <PriceRequestButton />
      </div>
    </div>
  );
};

const PriceRequestButton = () => {
  return (
    <div className="flex justify-center my-1">
      <Link
        to="/request-price"
        className="text-white bg-[#4695CE] hover:bg-[#53B18D] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
      >
        Request price
        <svg
          className="w-4 h-4 ml-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </Link>
    </div>
  );
};

const Products = () => {
  return (
    <>
      <div className="my-4 py-4" id="products">
        <h2 className="my-2 text-center text-3xl primary-text uppercase font-bold">
          Agricultural Products
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-[#4695CE] mb-8"></div>
        </div>
        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {productData.map((product, index) => (
              <Product key={index} name={product.name} image={product.image} description={product.description} details={product.details} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
