import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
import Notiflix from 'notiflix';
import IndustrialProducts from '../components/IndustrialProducts';

const IndustrialProduct = () => {
  useDocTitle('Marquee Commodity Group');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState([]);

  const clearErrors = () => {
    setErrors([]);
  };

  const clearInput = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setMessage('');
  };

  const sendEmail = (e) => {
    e.preventDefault();
  
    const subject = "Contact Form Submission";
    const body = `
      First Name: ${firstName}
      Last Name: ${lastName}
      Email: ${email}
      Phone: ${phone}
   
      ${message}
    `;
    const mailtoLink = `mailto:orders@marqueecommoditygroup.comsubject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
    console.log(mailtoLink)
    window.location.href = mailtoLink;
  };

  function sendEmailToBE(e) {
    e.preventDefault();
    document.getElementById('submitBtn').disabled = true;
    document.getElementById('submitBtn').innerHTML = 'Loading...';
    let fData = new FormData();
    fData.append('first_name', firstName);
    fData.append('last_name', lastName);
    fData.append('email', email);
    fData.append('phone_number', phone);
    fData.append('message', message);

    axios({
      method: 'post',
      url: process.env.REACT_APP_PRICE_REQUEST_API,
      data: fData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(function (response) {
        document.getElementById('submitBtn').disabled = false;
        document.getElementById('submitBtn').innerHTML = 'send message';
        clearInput();
        //handle success
        Notiflix.Report.success('Success', response.data.message, 'Okay');
      })
      .catch(function (error) {
        document.getElementById('submitBtn').disabled = false;
        document.getElementById('submitBtn').innerHTML = 'send message';
        //handle error
        const { response } = error;
        if (response.status === 500) {
          Notiflix.Report.failure(
            'An error occurred',
            response.data.message,
            'Okay'
          );
        }
        if (response.data.errors !== null) {
          setErrors(response.data.errors);
        }
      });
  }
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="demo"
        className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24"
      >
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <IndustrialProducts />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default IndustrialProduct;
