import React from 'react';
import { Link } from 'react-router-dom';
import aboutImg from '../images/about.jpg';

const About = () => {
  return (
    <>
      <div className="m-50 max-w-full p-2 md:p-1 h-5/6 mt-5" id="about">
         <h1 className="flex justify-center text-2xl text-center lg:text-left md:text-3xl primary-text font-bold">
            ABOUT THE COMPANY
            </h1>
          <div className="flex justify-center pt-2">
            <div className="w-44 border-b-4 border-[#4695CE] mb-8"></div>
          </div>
        <div
          className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
          data-aos="fade-up"
        >
          <div className="lg:w-1/5 flex flex-col lg:mx-4 justify-top">
            <img
              alt="about img"
              className="rounded-t float-right"
              src={aboutImg}
            />
          </div>
          <div
            className="flex-col text-left text-center justify-center lg:my-0 lg:justify-end w-full lg:w-4/5 px-2"
          >
            <div>
              <p className="text-l  md:text-xl  mb-5 text-gray-600 font-semibold">
              MGC , founded by Serial Entrepreneur Bentley GS Brar sources and operates in a specialized array of  marketplace commerical activities.
              </p>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default About;
